













import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'

import AddPersonToEventForm from '@/partials/forms/Events/AddPersonForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import { RawLocation } from 'vue-router'

@Component({
  components: {
    AddPersonToEventForm
  }
})
export default class AddSubscriberToEvent extends Mixins(FlashMessageMixin) {
  role: Role = {} as Role
  done = false
  person: EventPerson = 'subscriber'

  created () {
    axios.get('/roles/event/user')
      .then(response => {
        const roles: Roles = response.data.data
        const role = roles.find((role) => { return role.name === 'Inscrito' })
        if (role) {
          this.role = role
          this.done = true
        }
      })
  }

  postSubmit (message: flashMessage) {
    this.setFlashMessage(message)
    const redirectTo = this.$route.query.redirect
    if (!redirectTo) {
      this.$router.push({ name: 'EventSubscribers', params: { id: this.$route.params.eventId } })
    } else {
      this.$router.push(redirectTo as RawLocation)
    }
  }
}
